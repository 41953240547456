import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
// import * as common from '@/modules/common/models/index'


export function pintuanList(params: object): Promise<model.PintuanList> {
  return pyApi({
    method: 'GET',
    url: '/fairy/api/client/v1/fightgroupactivity/',
    params
  })
}