
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { ListSell } from '@/modules/common/models'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox
  }
})
export default class pintuanList extends Vue {
  isLoading: boolean = true
  total: number = 0
  page: number = 1
  count: number = 10
  title: string = '拼团'
  pintuanList: Array<model.PintuanList> = []
  mounted() {
    window.scrollTo(0, 0)
    this.renderPintuanList()
  }

  get isHaveData() {
    return this.pintuanList.length
  }

  get getMoreTips() {
    return `全部${this.title ? this.title : '拼团'} (${this.total})`
  }

  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderPintuanList()
  }

  renderPintuanList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.title = this.$route.query.title && String(this.$route.query.title)
    this.getPintuanList()
  }

  getPintuanList() {
    this.isLoading = true
    api.pintuanList({
      page: this.page,
      count: this.count
    }).then(res => {
      this.pintuanList = res.data
      this.total = res.page.total
      this.isLoading = false
    }).catch(res => {
      this.pintuanList = []
      this.total = 0
      this.isLoading = false
      console.error('getPintuanList = ', res)
    })
  }

  getListSellItem(item: model.PintuanList) {
    let listSellItem: ListSell = {
      content_id: item.product_identifier,
      type: item.product_category,
      title: item.name,
      brief: item.product.brief,
      indexpic: item.product.cover_image,
      update_time: item.update_time || '',
      highest_price: Number(item.related_skus[0].origin_price),
      lowest_price: Number(item.related_skus[0].discount_price),
      subscribe_count: 0,
      original_price: item.original_price,
      product_id: item.product_id
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'PintuanList',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        title: `${this.title}`
      }
    })
  }
}
